var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-text":"Downloading, please wait..."}},[_c('el-container',{staticStyle:{"height":"90vh","border":"1px solid #eee"}},[_c('el-aside',{staticStyle:{"background-color":"rgb(238, 241, 246)"},attrs:{"width":"35vh"}},[_c('el-menu',{attrs:{"default-openeds":['0'],"default-active":'0-0'}},_vm._l((_vm.selectedAttachmentFiles),function(item,index){return _c('el-submenu',{key:item.key,attrs:{"index":index.toString()}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-folder-opened"}),_vm._v(_vm._s(item.label))]),_vm._l((item.files || []),function(file,j){return _c('el-menu-item',{key:'file_' + index + j,attrs:{"index":(index + '-' + j).toString()},on:{"click":function($event){return _vm.getSelectedFile(index, j)}}},[_c('template',{slot:"title"},[_c('i',{class:_vm.getIcon(file)}),_vm._v(_vm._s(_vm._f("truncate")(file.name,24, "...")))])],2)})],2)}),1)],1),_c('el-container',{attrs:{"width":"80vh; height: 100vh;"}},[_c('el-main',[(
            _vm.selectedFile && _vm.isImage(_vm.selectedFile.file_type, _vm.selectedFile.name)
          )?_c('el-image',{staticStyle:{"width":"100%","height":"80vh"},attrs:{"src":_vm.selectedFile.download_url,"fit":'fill'}}):(
            _vm.selectedFile && _vm.isVideo(_vm.selectedFile.file_type, _vm.selectedFile.name)
          )?_c('video',{attrs:{"controls":"","src":_vm.selectedFile.download_url,"width":"640","height":"360"}},[_vm._v(" Your browser does not support the to play video. ")]):(
            _vm.selectedFile && _vm.isPDF(_vm.selectedFile.file_type, _vm.selectedFile.name)
          )?_c('embed',{attrs:{"src":_vm.selectedFile.download_url,"width":"100%","height":"100%","type":"application/pdf"}}):(_vm.selectedFile && _vm.selectedFile.download_url)?_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(" File format not supported to preview. please click on ")]),_c('el-link',{staticClass:"ml-1",attrs:{"type":"primary"},on:{"click":function($event){return _vm.downloadFile(_vm.selectedFile.download_url, _vm.selectedFile.name)}}},[_vm._v("Download")])],1):_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(" File not uploaded ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }