<template>
  <div v-loading="loading" element-loading-text="Downloading, please wait...">
    <el-container style="height: 90vh; border: 1px solid #eee">
      <el-aside width="35vh" style="background-color: rgb(238, 241, 246)">
        <el-menu :default-openeds="['0']" :default-active="'0-0'">
          <el-submenu
            v-for="(item, index) in selectedAttachmentFiles"
            :index="index.toString()"
            :key="item.key"
          >
            <template slot="title"
              ><i class="el-icon-folder-opened"></i>{{ item.label }}</template
            >
            <el-menu-item
              @click="getSelectedFile(index, j)"
              v-for="(file, j) in item.files || []"
              :index="(index + '-' + j).toString()"
              :key="'file_' + index + j"
            >
              <template slot="title"
                ><i :class="getIcon(file)"></i
                >{{ file.name | truncate(24, "...") }}</template
              >
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>

      <el-container width="80vh; height: 100vh;">
        <el-main>
          <el-image
            v-if="
              selectedFile && isImage(selectedFile.file_type, selectedFile.name)
            "
            style="width: 100%; height: 80vh"
            :src="selectedFile.download_url"
            :fit="'fill'"
          ></el-image>
          <video
            controls
            :src="selectedFile.download_url"
            width="640"
            height="360"
            v-else-if="
              selectedFile && isVideo(selectedFile.file_type, selectedFile.name)
            "
          >
            Your browser does not support the to play video.
          </video>
          <embed
            v-else-if="
              selectedFile && isPDF(selectedFile.file_type, selectedFile.name)
            "
            :src="selectedFile.download_url"
            width="100%"
            height="100%"
            type="application/pdf"
          />
          <div
            v-else-if="selectedFile && selectedFile.download_url"
            class="d-flex"
          >
            <span> File format not supported to preview. please click on </span>
            <el-link
              type="primary"
              @click="
                downloadFile(selectedFile.download_url, selectedFile.name)
              "
              class="ml-1"
              >Download</el-link
            >
          </div>
          <div v-else class="d-flex">
            <span> File not uploaded </span>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { postAPICall } from "@/helpers/httpHelper";

export default {
  name: "FilesPreviewWidget",
  data() {
    return {
      loading: false,
      selectedAttachmentFiles: [],
      activeAttachment: 1,
      selectedFileIndex: "",
      selectedFile: null,
    };
  },
  mounted() {
    this.fetchFilesData();
  },
  props: ["allEntityFields", "selectedAttachmentData"],
  methods: {
    getIcon(file) {
      if (file) {
        if (this.isImage(file.file_type, file.name)) {
          return "el-icon-picture-outline";
        } else if (this.isVideo(file.file_type, file.name)) {
          return "el-icon-video-camera";
        } else if (this.isPDF(file.file_type, file.name)) {
          return "el-icon-document";
        }
      }
      return "el-icon-folder";
    },
    async downloadFile(url, fileName) {
      this.loading = true;
      await fetch(url, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      this.loading = false;
    },
    isImage(fileType, fileName) {
      const imageTypes = /^image\/(jpeg|png|gif|bmp|svg\+xml|jpeg)$/;
      const imageExtensions = /\.(jpeg|jpg|png|gif|bmp|svg)$/i;
      return imageTypes.test(fileType) || imageExtensions.test(fileName);
    },
    isVideo(fileType, fileName) {
      const videoTypes = /^video\/(mp4|avi|mov|wmv|mkv|flv|webm|ogg)$/;
      const videoExtensions = /\.(mp4|avi|mov|wmv|mkv|flv|webm|ogg)$/i;
      return videoTypes.test(fileType) || videoExtensions.test(fileName);
    },
    isPDF(fileType, fileName) {
      const pdfType = /^application\/pdf$/;
      const pdfExtension = /\.pdf$/i;
      return pdfType.test(fileType) || pdfExtension.test(fileName);
    },
    getSelectedFile(i, j) {
      let index = i + "-" + j;
      if (index === this.selectedFileIndex) {
        return;
      }
      if (this.selectedAttachmentFiles[i]?.files?.[j]) {
        this.loading = true;
        setTimeout(() => {
          this.selectedFile = this.selectedAttachmentFiles[i].files[j];
          this.selectedFileIndex = index;
          this.loading = false;
        }, 100);
      }
    },
    async fetchFilesData() {
      let attachmentFields = [],
        allFiles = [],
        filesMap = {},
        fieldMap = {};
      this.loading = true;
      this.allEntityFields.forEach((f) => {
        if (f?.input_type == "FILE") {
          attachmentFields.push(f);
          let files =
            this.selectedAttachmentData?.entityData?.[f.template_id]?.[f.key]
              ?.files || [];
          fieldMap[f.key] = files;
          allFiles = [...allFiles, ...files];
        }
      });
      await Promise.all(
        allFiles.map(async (data) => {
          let fileName = data.name;
          fileName = await fileName.replace(/\//g, "-");
          fileName = await fileName.replace(/ /g, "_");
          fileName = await fileName.replace(/[()]/g, "");
          if (data && !data.path) {
            data.path = `template-data-documents/${fileName}`;
          }
          const response = await postAPICall(
            "POST",
            "/templates-data/file-download-url",
            {
              path: data.path,
            }
          );
          filesMap[data.path] = response?.data?.download_url;
        })
      );
      this.selectedAttachmentFiles = attachmentFields.map((f) => {
        let files = fieldMap[f.key];
        return {
          label: f.label,
          files: files.map((data) => {
            let fileName = data.name;
            fileName = fileName.replace(/\//g, "-");
            fileName = fileName.replace(/ /g, "_");
            fileName = fileName.replace(/[()]/g, "");
            if (data && !data.path) {
              data.path = `template-data-documents/${fileName}`;
            }
            data["download_url"] = filesMap[data.path];
            return data;
          }),
          key: f.key,
        };
      });
      this.getSelectedFile(0, 0);
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>
